import {
    Swiper,
    Keyboard,
    Navigation,
    History,
    Autoplay,
    Lazy,
    EffectFade,
    Controller,
    Thumbs
} from 'swiper/dist/js/swiper.esm';
// Use modules
Swiper.use([Keyboard, Navigation, History, Autoplay, Lazy, EffectFade, Controller, Thumbs]);

import { AbstractCarousel } from './AbstractCarousel';

const breakpoint = window.matchMedia('(max-width: 767px)');
/**
 *
 *
 *
 *
 */
export class Testimonials extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode);
        this.$slidesPerView = parseInt(this.$root.querySelector('[data-slider-thumbs]').dataset.slidePerView);

        // Our initial settings object for Swiper
        this.settings = {
            thumbs: {
                slidesPerView: this.$slidesPerView,
                freeMode: true,
                touchRatio: 0,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                preventClicks: false,
                preventClicksPropagation: false,
                slideToClickedSlide: true,
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    nextEl: '.testimonials-thumbs__slidernav-next',
                    prevEl: '.testimonials-thumbs__slidernav-prev',
                },
                // Responsive breakpoints
                breakpoints: {
                    // when window width is <= 480px
                    480: {
                        slidesPerView: 1,
                    },
                    // when window width is <= 767px
                    767: {
                        slidesPerView: 3,
                        touchRatio: 1
                    }
                }
            },
            preview: {
                autoHeight: true,
                effect: 'fade',
                simulateTouch: false,
                fadeEffect: {
                    crossFade: true
                },
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    nextEl: '.testimonials-thumbs__slidernav-next',
                    prevEl: '.testimonials-thumbs__slidernav-prev',
                },
            }
        };

        // We'll merge any "options" passed in over our defaults
        Object.assign(this.settings, options);

        // Keep track of our important nodes
        this.$thumbs = this.$root.querySelector('[data-slider-thumbs]');
        this.$preview = this.$root.querySelector('[data-slider-preview]');
        this.$thumbWrapper = this.$thumbs.querySelector('.swiper-wrapper');
        this.$thumbsTotalSlides = this.$thumbs.querySelectorAll('.swiper-slide');

        // Init Swiper
        this.thumbsInit = new Swiper(this.$thumbs, this.settings.thumbs);
        this.previewInit = new Swiper(this.$preview, this.settings.preview);

        // Sync each sliders
        // this.thumbsInit.controller.control = this.previewInit
        // this.previewInit.controller.control = this.thumbsInit
        this.previewInit.thumbs.swiper = this.thumbsInit;

        this.thumbsInit.update();
        this.previewInit.update();

        this.$thumbsTotalSlides.forEach((item, index) => {
            item.setAttribute('data-slide-index', index);
            item.addEventListener('click', this._slideTo);
        });

        // If we have less than this.$slidesPerView.length <= this.$slidesPerView
        this._hideNavigation();
    }

    _hideNavigation = () => {
        // If [data-slide] > [slides-per-view]
        if (this.$thumbsTotalSlides.length <= this.$slidesPerView) {
            // Hide navigation
            this.previewInit.navigation.nextEl.style.display = 'none';
            this.previewInit.navigation.prevEl.style.display = 'none';

            // Center items
            this.$thumbWrapper.classList.add('justify-center');
        }
    }

    _slideTo = (event) => {
        const index = event.currentTarget.dataset.slideIndex;
        this.previewInit.slideTo(index);
    }
}
