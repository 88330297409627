import {
    Swiper,
    Keyboard,
    Navigation,
    History,
    Autoplay,
    Lazy,
    EffectFade,
} from 'swiper/dist/js/swiper.esm';
// Use modules
Swiper.use([Keyboard, Navigation, History, Autoplay, Lazy, EffectFade]);

import { AbstractCarousel } from './AbstractCarousel';
/**
 *
 *
 *
 *
 */
export class HeroImageTextSlider extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode);

        // Our initial settings object for Swiper
        this.settings = {
            heroSlider: {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                touchRatio: 0,
                lazy: false,
                loadPrevNext: true,
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    nextEl: '.hero-slider__slidernav-next',
                    prevEl: '.hero-slider__slidernav-prev',
                },
                // Responsive breakpoints
                breakpoints: {
                    // when window width is <= 1023px
                    1023: {
                        touchRatio: 1,
                    }
                }
            }
        };

        // We'll merge any "options" passed in over our defaults
        Object.assign(this.settings, options);

        // Keep track of our important nodes
        this.$slider = this.$root.querySelector('[data-slider-hero]');
        this.$totalSlides = this.$slider.querySelectorAll('.swiper-slide');
        this.$navigations = this.$root.querySelectorAll('.swiper-nav');

        if (this.$totalSlides.length !== 1 ) {
            // Init Swiper
            this.sliderInit = new Swiper(this.$slider, this.settings.heroSlider);
            this.sliderInit.update();
        } else {
            this.$navigations.forEach((nav) => {
                // Hide navigation
                nav.classList.add('hidden');
            });
        }
    }
}
