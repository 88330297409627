/**
 * Any common methods for could go here!
 */
export class AbstractTabs {
    constructor(rootNode) {
        if (new.target === AbstractTabs) {
            throw new TypeError("Cannot construct Abstract instances directly");
        }

        this.$root = rootNode;
    }
}
