
/**
 * Autosubmit forms on dropdown change if this data attribute is set
 */
function init() {
    var submitForm = (event) => {
        const formId = event.target.getAttribute('data-auto-submit')
        const $form = document.getElementById(formId)
        if ($form) {
            $form.submit()
        } else {
            console.warn('Could not locate form: ', $form)
        }
    }
    let $els = document.querySelectorAll('[data-auto-submit]')
    for (let i = 0; i < $els.length; ++i) {
        let $el = $els[i]
        if ($el.addEventListener) {
            $el.addEventListener('change', submitForm, false);
        } else if ($el.attachEvent) {
            $el.attachEvent('on' + 'change', submitForm)
        }
    }
}

export default init
