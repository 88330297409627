// ----------------
// UI Modules - Global
// ----------------
// This is UI modules for Global instances
// ---------------------------------------------------------------

import header from './header';
import headerSearch from './header-search';
import headerHam from './ham';
import swiperModules from './swiper';
import tabsModules from './tabs';
import autosubmit from './forms/autosubmit';
import glightbox from './glightbox';

function init() {
    header(document.querySelector("header"));
    headerSearch();
    headerHam();
    swiperModules();
    tabsModules();
    autosubmit();
    glightbox();
}

export default init
