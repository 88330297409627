import { AbstractTabs } from './AbstractTabs';
/**
 *
 *
 *  [data-toggle-class]: collection of classes separated with space.
 *
 */
export class ContactMethodTab extends AbstractTabs {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode);

        // Our initial settings object for Swiper
        this.settings = {

        };

        // We'll merge any "options" passed in over our defaults
        Object.assign(this.settings, options);

        // Keep track of our important nodes
        this.$tabs = this.$root.querySelector('[data-tabs]');
        this.$tabsToggleClass = this.$tabs.dataset.toggleClass.split(' ');
        this.$tabItems = this.$tabs.querySelectorAll('[data-tabs-anchor]');
        this.$tabContent = this.$root.querySelector('[data-tab-content]');
        this.$contentToggleClass = this.$tabContent.dataset.toggleClass.split(' ');
        this.$contentItems = this.$tabContent.querySelectorAll('[data-content-panel]');

        // Add event click for tabs items
        this.$tabItems.forEach((item, index) => {
            item.addEventListener('click', this._clickTabsCallback);
        });
    }

    // Helper function to remove active classes
    _removeActiveClasses = (arrClass, item) => {
        arrClass.forEach((c) => {
            item.classList.remove(c);
        });
    }

    // Helper function to add active classes
    _addActiveClasses = (arrClass, item) => {
        arrClass.forEach((c) => {
            item.classList.add(c);
        });
    }

    // Set active content
    _setActiveContent = (id) => {
        this.$contentItems.forEach((item) => {
            if( item.getAttribute('id') === id ) {
                this._removeActiveClasses(this.$contentToggleClass, item);
            } else {
                this._addActiveClasses(this.$contentToggleClass, item);
            }
        });
    }

    // Set active tab
    _setActiveTabs = (clickedItem) => {
        this.$tabItems.forEach((item) => {
            this._removeActiveClasses(this.$tabsToggleClass, item);
        });
        this._addActiveClasses(this.$tabsToggleClass, clickedItem);
    }

    // Click callback
    _clickTabsCallback = (event) => {
        // track hash id w/o '#'
        const tabHash = event.currentTarget.getAttribute('href').replace(/#/g,'');
        this._setActiveTabs(event.currentTarget);
        this._setActiveContent(tabHash);
        // avoid from rewriting the URL with hash
        event.preventDefault();
    }
}
