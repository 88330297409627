let header, headerSearchInput, headerSearchTrigger, activeSearchClass;
// Active class
activeSearchClass = 'search-is-active';
// Header
header = document.querySelector('header');

function triggerCallback(event) {
    if( !header.classList.contains(activeSearchClass) ) {
        return expandSearch();
    } else if ( header.classList.contains(activeSearchClass) ) {
        return hideSearch();
    }
}

function expandSearch() {
    header.classList.add(activeSearchClass);
    setTimeout(setInputFocus, 300);
}

function setInputFocus() {
    headerSearchInput.focus();
}

function hideSearch() {
    header.classList.remove(activeSearchClass);
    headerSearchInput.blur();
}

function init() {
    // We make sure header tag that wraps the whole header && has data attrib. [data-header-search].
    if( header.hasAttribute('data-header-search') !== false && header !== null ) {
        // Search input
        headerSearchInput = document.querySelector('[data-header-search-input]');
        // Search trigger
        headerSearchTrigger = document.querySelectorAll('[data-header-search-trigger]');

        headerSearchTrigger.forEach((trigger) => {
            trigger.addEventListener('click', triggerCallback);
        });
    }
}

export default init
