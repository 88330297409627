// ----------------
// Tabs Modules
// ----------------
import {ContactMethodTab} from '../../tabs/ContactMethodTab';

function init() {
    let $tabs = document.querySelectorAll('[data-sg-tab]');
    $tabs.forEach(($element) => {
        // This will tell us the type of tabs to create
        let type = $element.dataset.sgTab;
        if( type === 'ContactMethodTab' ) {
            new ContactMethodTab($element);
        }
        // else if...
    })
}
export default init
