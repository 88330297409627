// ----------------
// Swiper Modules
// ----------------
import {Testimonials} from '../../carousels/Testimonials';
import {HeroImageTextSlider} from '../../carousels/HeroImageTextSlider';

function init() {
    let $sliders = document.querySelectorAll('[data-sg-slider]')
    $sliders.forEach(($element) => {
        // This will tell us the type of slider to create
        let type = $element.dataset.sgSlider;
        if( type === 'Testimonials' ) {
            new Testimonials($element);
        }
        else if( type === 'HeroImageTextSlider' ) {
            new HeroImageTextSlider($element);
        }
    })
}
export default init
