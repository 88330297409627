import styles from '../css/app.pcss'
import '../img/logo-full.svg'
import * as components from './components';
import quicklink from 'quicklink/dist/quicklink.mjs'
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/respimg/ls.respimg';

document.addEventListener('DOMContentLoaded', function () {
    quicklink({timeoutFn: networkIdleCallback, ignores: /^admin\/*/});
});

// App main
const main = async (module) => {
    components.globalJS();
    components.pagesJS();
};

main().then((value) => {
});
// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

const DOMContentLoad = new Promise(resolve => {
    document.addEventListener('DOMContentLoaded', resolve);
});

// navigator.serviceWorker.getRegistration()
//     .then(registration => {
//         if (!registration) {
//             console.warn('`networkIdleCallback` was called before a service worker was registered.');
//             console.warn('`networkIdleCallback` is ineffective without a working service worker');
//         }
//     });

//add simple support for background images:
document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

/**
 * networkIdleCallback works similar to requestIdleCallback,
 * detecting and notifying you when network activity goes idle
 * in your current tab.
 * @param {*} fn - A valid function
 * @param {*} options - An options object
 */
function networkIdleCallback(fn, options = {timeout: 0}) {
    // Call the function immediately if required features are absent
    if (
        !('MessageChannel' in window) ||
        !('serviceWorker' in navigator) ||
        !navigator.serviceWorker.controller
    ) {
        DOMContentLoad.then(() => fn({didTimeout: false}));
        return;
    }

    const messageChannel = new MessageChannel();
    navigator.serviceWorker.controller
        .postMessage(
            'NETWORK_IDLE_ENQUIRY',
            [messageChannel.port2],
        );

    const timeoutId = setTimeout(() => {
        const cbToPop = networkIdleCallback.__callbacks__
            .find(cb => cb.id === timeoutId);

        networkIdleCallback.__popCallback__(cbToPop, true);
    }, options.timeout);

    networkIdleCallback.__callbacks__.push({
        id: timeoutId,
        fn,
        timeout: options.timeout,
    });

    messageChannel.port1.addEventListener('message', handleMessage);
    messageChannel.port1.start();
}

/*
function cancelNetworkIdleCallback(callbackId) {
  clearTimeout(callbackId);
  networkIdleCallback.__callbacks__ = networkIdleCallback.__callbacks__
      .find(cb => cb.id === callbackId);
}
*/

networkIdleCallback.__popCallback__ = (callback, didTimeout) => {
    DOMContentLoad.then(() => {
        const cbToPop = networkIdleCallback.__callbacks__
            .find(cb => cb.id === callback.id);

        if (cbToPop) {
            cbToPop.fn({didTimeout});
            clearTimeout(cbToPop.id);
            networkIdleCallback.__callbacks__ = networkIdleCallback.__callbacks__.filter(
                cb => cb.id !== callback.id);
        }
    });
};

networkIdleCallback.__callbacks__ = [];

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', handleMessage);
}

/**
 * Handle message passing
 * @param {*} event - A valid event
 */
function handleMessage(event) {
    if (!event.data) {
        return;
    }

    switch (event.data) {
        case 'NETWORK_IDLE_ENQUIRY_RESULT_IDLE':
        case 'NETWORK_IDLE_CALLBACK':
            networkIdleCallback.__callbacks__.forEach(callback => {
                networkIdleCallback.__popCallback__(callback, false);
            });
            break;
    }
}