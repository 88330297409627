let HamNav, hamburgers, navToggleClasses, hamToggleClass;
// Ham Toggle class
hamToggleClass = 'ham-is-active';
// Nav Toggle classes
navToggleClasses = ['opacity-0', 'invisible'];
// Ham Nav
HamNav = document.querySelector('[data-ham-nav]');
// Hamburgers
hamburgers = Array.from(document.querySelectorAll('[data-hamburger]'));
// Header
const header = document.querySelector('header');
// Html Element
let rootElement = document.documentElement;

function triggerCallback(event) {
    hamburgers.forEach((ham) => {
        ham.classList.toggle(hamToggleClass);
    });
    navToggleClasses.forEach((c) => {
        HamNav.classList.toggle(c);

        // If navToggleClasses been removed/toggled, inline translateY(0) to avoid header from going anywhere.
        if( !HamNav.matches('.'+c) ) {
            // prevent scrolling and initiate headroomjs classes toggle
            rootElement.style.overflow = 'hidden';
            // This will be an extra precausion just to be sure
            header.style.transform = 'translateY(0)';
        } else if( HamNav.matches('.'+c) ) {
            header.removeAttribute('style');
            rootElement.removeAttribute('style');
        }
    });
}

function init() {
    if( hamburgers.length !== 0 && HamNav !== null ) {
        hamburgers.forEach((ham) => {
            ham.addEventListener('click', triggerCallback);
        });
    }
}

export default init
